export default {
    name: "expiringwarrants",
    created () {
        window.getRicToDetails = this.getRicToDetails;
    },
    mounted () {
        this.$nextTick(function () {
            this.getRicList();
        });
    },
    data () {
        return {};
    },
    methods: {
        getRicList () {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetRICList",
                data: { token: "webkey" },
                success: (data) => {
                    if (data.status == "SUCCESS") {
                        this.expiringDataLoad(data.ric);
                    }

                }
            })
        },
        expiringDataLoad (ric_data) {
            $.ajax({
                type: "POST",
                dataType: 'json',
                url: this.$js.webservice + "GetDeployedExpireWarrant",
                data: { token: "webkey" },
                success: (response) => {
                    //console.log(response);
                    if (response.status == 'SUCCESS') {
                        var datelist = [];
                        var dateText = [];
                        for (var key in response.text) {
                            datelist.push(this.$js.formatYearDate(key));
                            dateText.push(response.text[key]);
                        }
                        var aHtml = "";
                        var divHtml = "";
                        for (var i = 0; i < datelist.length; i++) {
                            var href = "#tab" + i;
                            var hid = "tab" + i;
                            var id = "expiryWarrants" + i;
                            if (i == 0) {
                                //左边date拼接
                                aHtml += '<li class="nav-item">';
                                aHtml += '<a class="nav-link nav-item-text active" id="pills-home-tab" data-toggle="pill" href="' + href + '" role="tab" aria-controls="pills-home" aria-selected="true">'+this.$t("m.Ed") +': ' + datelist[i] + '</a>';
                                aHtml += '</li>';
                                //右边div拼接
                                divHtml += '<div class="tab-pane statis-tabletab1 show active" id="' + hid + '" role="tabpanel" aria-labelledby="pills-home-tab">';
                                divHtml += '<table class="table table-striped">';
                                divHtml += '<thead>';
                                divHtml += '<tr class="text-center font-weight-bold text-white text-nowrap">';
                                divHtml += '<th>'+this.$t("m.WN") +'</th>';
                                divHtml += '<th>'+this.$t("m.Ul") +'</th>';
                                divHtml += '<th> '+this.$t("m.TT") +' </th>';
                                divHtml += '<th>'+this.$t("m.OT") +'</th>';
                                divHtml += '<th>'+this.$t("m.EP") +'</th>';
                                divHtml += '<th>'+this.$t("m.ER") +'</th>';
                                divHtml += '<th>'+this.$t("m.ED") +'</th>';
                                divHtml += '</tr>';
                                divHtml += '</thead>';
                                divHtml += '<tbody id="' + id + '"></tbody>';
                                divHtml += '</table>';
                                divHtml += '</div>';
                            } else {
                                //左边date拼接
                                aHtml += '<li class="nav-item">';
                                aHtml += '<a class="nav-link nav-item-text" id="pills-home-tab" data-toggle="pill" href="' + href + '" role="tab" aria-controls="pills-home" aria-selected="true">'+this.$t("m.Ed") +': ' + datelist[i] + '</a>';
                                aHtml += '</li>';
                                //右边div拼接
                                divHtml += '<div class="tab-pane statis-tabletab1 show" id="' + hid + '" role="tabpanel" aria-labelledby="pills-home-tab">';
                                divHtml += '<table class="table table-striped">';
                                divHtml += '<thead>';
                                divHtml += '<tr class="text-center font-weight-bold text-white text-nowrap">';
                                divHtml += '<th>'+this.$t("m.WN") +'</th>';
                                divHtml += '<th>'+this.$t("m.Ul") +'</th>';
                                divHtml += '<th> '+this.$t("m.TT") +' </th>';
                                divHtml += '<th>'+this.$t("m.OT") +'</th>';
                                divHtml += '<th>'+this.$t("m.EP") +'</th>';
                                divHtml += '<th>'+this.$t("m.ER") +'</th>';
                                divHtml += '<th>'+this.$t("m.ED") +'</th>';
                                divHtml += '</tr>';
                                divHtml += '</thead>';
                                divHtml += '<tbody id="' + id + '"></tbody>';
                                divHtml += '</table>';
                                divHtml += '</div>';
                            }
                        }
                        $("#pills-tab").append(aHtml);
                        $("#pills-tabContent").append(divHtml);
                        // var _this = this
                        // $(".nav-item").click(function() {
                        //     _this.$js.SetAnchor($('#pills-tabContent').offset().top)
                        //   })
                        for (var i = 0; i < dateText.length; i++) {
                            $("#expiryWarrants" + i).append(this.htmlAppend(dateText[i], ric_data));
                        }
                    }
                },
                error: function (XMLHttpRequest) { }
            });
        },
        htmlAppend (data, ric_data) {
            var htmlStr = "";
            for (var i = 0; i < data.length; i++) {
                var isRicExist = false;
                var theRic = '';
                $.each(ric_data, function (index, datas) {
                    if (datas.dsply_nmll == data[i].name.replace(new RegExp('&amp;', 'g'), "&")) {
                        isRicExist = true;
                        theRic = datas.ric;
                    }
                });
                htmlStr += "<tr class='text-center'>";
                if (isRicExist) {
                    htmlStr += "<td><a href='javascript:getRicToDetails(\"" + theRic + " " + data[i].name + "\")'>" + data[i].name + "</a></td>";
                } else {
                    htmlStr += "<td>" + data[i].name + "</td>";
                }
                htmlStr += "<td>" + data[i].underlying + "</td>";
                htmlStr += "<td>" + this.$js.formatDate(data[i].listing_date) + "</td>";
                htmlStr += "<td>" + data[i].type + "</td>";
                htmlStr += "<td>" + (data[i].eprice !== 'N/A' ? this.$js.curreryRetrun(data[i].eprice.split(" ")[0]) + " " + this.$js.formatNumber(data[i].eprice.split(" ")[1], 0, 1) : "N/A") + "</td>";
                htmlStr += "<td>" + this.$js.formatNumber(data[i].ratio, 4, 0) + "</td>";
                htmlStr += "<td>" + this.$js.formatDate(data[i].expiry_date) + "</td>";
                htmlStr += "</tr>";
            }
            return htmlStr;
        },
        getRicToDetails (ric) {
            localStorage.setItem('detailsRic', ric.split(" ")[0]);
            var routes = this.$router.resolve({
                path: "/id/WarrantDetails",
                query: { dsplay_nmll: ric.split(" ")[1] }
            });
            location.href = routes.href;
        }
    }
}